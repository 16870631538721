<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>
  
  
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';

import CommissionReportServices from '../Script/CommissionReportService.js';
  
export default {
    name: 'CommissionContactReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateFrom', 'dateTo'],
    data(){
        var dateFrom = this.$props.dateFrom;
        var dateTo = this.$props.dateTo;

        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                            }
                            return { 
                                query: CommissionReportServices.readCommissionContactQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetCommissionReport.commission_report_per_contact == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommissionReport.commission_report_per_contact;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetCommissionReport.commission_report_per_contact == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommissionReport.commission_report_per_contact.length;
                        }
                    },
                    model: {
                        fields: {
                            max_commission: {type: "number"},
                            given_commission: {type: "number"},
                            invoiced_project: {type: "number"},
                            invoiced_payment_project: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
                // sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { field: "head_contact_name", title: "Nama Afiliasi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "contact_person_name", title: "Nama Kontak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "max_commission", title: "Max Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "given_commission", title: "Komisi yg diberikan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "commission_paid_date", title: "Tanggal Dibayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                //     template: "#= kendo.toString(kendo.parseDate(new Date(commission_paid_date).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                //     filterable : {
                //         ui: function (element) {
                //             element.kendoDatePicker({
                //                 format: "yyyy-MM-dd"
                //             });
                //         }
                //     }
                // },
                { field: "invoiced_project", title: "Nilai Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "invoiced_payment_project", title: "Nilai Invoice Terbayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: async function() {
    },
    methods: {
        async exportExcel(DateFrom, DateTo) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await CommissionReportServices.commissionReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Komisi_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = CommissionReportServices.commissionReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    },
}
</script>