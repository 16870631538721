import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';


class BookingOrderReportService {
    readBookingReportQuery(){
        var query = `
            query ($startDate: String!, $endDate: String!) {
                GetReportingBookingOrder(start_date:$startDate, end_date:$endDate) {
                    booking_order_created_date
                    customer_id
                    contact_name
                    purchase_order_number
                    project_id
                    project_name
                    product_code
                    item_name
                    base_uom
                    quantity
                    quantity_purchase_order
                    remaining
                    purchase_order_date
                    reporting_booking_order_detail {
                        sequence
                        purchase_order_id
                        purchase_order_number
                        purchase_order_date
                        item_id
                        item_name
                        quantity
                        quantity_purchase_order
                    }
                } 
            }`;
        return query;
    }
    
    async getBookingReportQuery(variables){
        var query = `
            query ($startDate: String!, $endDate: String!) {
                GetReportingBookingOrder(start_date:$startDate, end_date:$endDate) {
                    booking_order_created_date
                    customer_id
                    contact_name
                    purchase_order_number
                    project_id
                    project_name
                    product_code
                    item_name
                    base_uom
                    quantity
                    quantity_purchase_order
                    remaining
                    purchase_order_date
                    reporting_booking_order_detail {
                        sequence
                        purchase_order_id
                        purchase_order_number
                        purchase_order_date
                        item_id
                        item_name
                        quantity
                        quantity_purchase_order
                    }
                } 
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetReportingBookingOrder;
    }

    bookingReportExcelHeaderSize(){
        var size = [
            {width: 15}, //colA
            {width: 25}, //colB
            {width: 25}, //colC
            {width: 15}, //colD
            {width: 30}, //colE
            {width: 20}, //colF
            {width: 30}, //colG
            {width: 10}, //colH
            {width: 15}, //colI
            {width: 10}, //colJ
            {width: 10}, //colK
        ];

        return size;
    }

    bookingReportExcel(data, variables){
        // const uniques = [];
        // data.forEach((t) => !uniques.includes(t.customer_id) && uniques.push(t.customer_id));

        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'BOOKING ORDER',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            var row5 = {
                colA : 'Tgl Dibuat',
                colB : 'Customer',
                colC : 'No PO',
                colD : 'Tgl PO',
                colE : 'Project',
                colF : 'Kode Item',
                colG : 'Nama Item',
                colH : 'Satuan',
                colI : 'Total Bookingan',
                colJ : 'Sisa',
                colK : 'Qty PO'
            };
            arrayObject.push(row5);

            for (let i= 0; i < data.length; i++) {
                var row = {
                    colA : { v: data[i].booking_order_created_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colB : data[i].contact_name,
                    colC : data[i].purchase_order_number,
                    colD : { v: data[i].purchase_order_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colE : data[i].project_name,
                    colF : data[i].product_code,
                    colG : data[i].item_name,
                    colH : data[i].base_uom,
                    colI : data[i].quantity,
                    colJ : data[i].remaining,
                    colK : data[i].quantity_purchase_order,
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new BookingOrderReportService();