import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class BorrowingToolsService {
    readBorrowingToolsQuery(){
        var query = `
            query ($startDate:String!, $endDate:String!) {
                GetTransactionProjectBorrowedToolsReport (start_date:$startDate, end_date:$endDate) {
                    borrowed_tools {
                        created_at
                        project_name
                        project_code
                        pre_project_name
                        sales_name
                        storage_name
                        limit
                        invoiced_projects
                        invoiced_payment_projects
                        total_item_per_project
                        total_quantity_per_project
                        Details {
                            item_id
                            product_code
                            item_name
                            item_type
                            base_uom
                            uom
                            conv_amount
                            quantity
                        }
                    }
                }
            }
        `;
        return query;
    }
    
    async getBorrowingToolsSummary(variables) {
        var query = gql`
            query ($startDate:String!, $endDate:String!) {
                GetTransactionProjectBorrowedToolsReport (start_date:$startDate, end_date:$endDate) {
                    summary {
                        total_item
                        total_quantity
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetTransactionProjectBorrowedToolsReport;
    }
    
    borrowingToolsReportExcelHeaderSize(){
        var size = [
            {width: 30}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 50}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
        ];
        return size;
    }

    borrowingToolsReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'PEMINJAMAN ALAT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            var row5 = {
                colA : 'Nama Project',
                colB : 'Nama Sales',
                colC : 'Gudang',
                colD : 'Nilai Invoice',
                colE : 'Nilai Invoice Terbayar',
                colF : 'Kode Barang',
                colG : 'Nama Barang',
                colH : 'Tipe Barang',
                colI : 'UOM',
                colJ : 'Quantity'
            };
            arrayObject.push(row5);

            for (let i= 0; i < data.length; i++) {
                var detail = data[i].Details;

                for (let j= 0; j < detail.length; j++) {
                    var row = {
                        colA : data[i].project_name,
                        colB : data[i].sales_name,
                        colC : data[i].storage_name,
                        colD : { v: data[i].invoiced_project == null ? 0 : data[i].invoiced_project, t: 'n' , z: numberFormat },
                        colE : { v: data[i].invoiced_payment_project == null ? 0 : data[i].invoiced_payment_project, t: 'n' , z: numberFormat },
                        colF : detail[j].product_code,
                        colG : detail[j].item_name,
                        colH : detail[j].item_type,
                        colI : detail[j].uom,
                        colJ : { v: detail[j].quantity == null ? 0 : detail[j].quantity, t: 'n' , z: numberFormat }
                    };
                    arrayObject.push(row);
                }
            }
        }

        return arrayObject;
    }
}

export default new BorrowingToolsService();