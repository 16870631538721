import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class CommissionService {
    readCommissionContactQuery(){
        var query = `
            query ($startDate:String!, $endDate:String!) {
                GetCommissionReport (start_date:$startDate, end_date:$endDate) {
                    commission_report_per_contact {
                        head_contact_name
                        contact_person_name
                        project_name
                        max_commission
                        given_commission
                        commission_paid_date
                        invoiced_project
                        invoiced_payment_project
                    }
                }
            }
        `;
        return query;
    }
    
    readCommissionProjectQuery(){
        var query = `
            query ($startDate:String!, $endDate:String!) {
                GetCommissionReport (start_date:$startDate, end_date:$endDate) {
                    commission_report_per_project {
                        project_name
                        invoiced_project
                        invoiced_payment_project
                        total_given_commission
                        total_max_commission
                        Details {
                            head_contact_name
                            contact_person_name
                            max_commission
                            given_commission
                            commission_paid_date
                        }
                    }
                }
            }
        `;
        return query;
    }
    
    async getCommissionSummary(variables) {
        var query = gql`
            query ($startDate:String!, $endDate:String!) {
                GetCommissionReport (start_date:$startDate, end_date:$endDate) {
                    summary {
                        total_paid_commission
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetCommissionReport;
    }
    
    commissionReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            // {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
        ];

        return size;
    }

    commissionReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'KOMISI',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            var row5 = {
                colA : 'Nama Afiliasi',
                colB : 'Nama Kontak',
                colC : 'Nama Project',
                colD : 'Max Komisi',
                colE : 'Komisi yg diberikan',
                // colF : 'Tanggal Dibayar',
                colG : 'Nilai Invoice',
                colH : 'Nilai Invoice Terbayar'
            };
            arrayObject.push(row5);

            for (let i= 0; i < data.length; i++) {
                var row = {
                    colA : data[i].head_contact_name,
                    colB : data[i].contact_person_name,
                    colC : data[i].project_name,
                    colD : { v: data[i].max_commission, t: 'n' , z: numberFormat },
                    colE : { v: data[i].given_commission, t: 'n' , z: numberFormat },
                    // colF : { v: data[i].commission_paid_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colG : { v: data[i].invoiced_project == null ? 0 : data[i].invoiced_project, t: 'n' , z: numberFormat },
                    colH : { v: data[i].invoiced_payment_project == null ? 0 : data[i].invoiced_payment_project, t: 'n' , z: numberFormat }
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}

export default new CommissionService();