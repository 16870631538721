<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
    </div>
</template>
  
  
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';

import borrowingToolsReportServices from '../Script/BorrowingToolsService.js';
  
export default {
    name: 'BorrowingToolsReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateFrom', 'dateTo'],
    data(){
        var dateFrom = this.$props.dateFrom;
        var dateTo = this.$props.dateTo;

        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                            }
                            return { 
                                query: borrowingToolsReportServices.readBorrowingToolsQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionProjectBorrowedToolsReport.borrowed_tools == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionProjectBorrowedToolsReport.borrowed_tools;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetTransactionProjectBorrowedToolsReport.borrowed_tools == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionProjectBorrowedToolsReport.borrowed_tools.length;
                        }
                    },
                    model: {
                        fields: {
                            invoiced_project: {type: "number"},
                            invoiced_payment_project: {type: "number"},
                            total_item_per_project: {type: "number"},
                            total_quantity_per_project: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
            }),
            columns:  [
                { field: "project_code", title: "Kode Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_name", title: "Nama Pra Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "sales_name", title: "Nama Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "storage_name", title: "Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "invoiced_projects", title: "Nilai Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes: { style:"text-align:right"} 
                },
                { field: "invoiced_payment_projects", title: "Nilai Invoice Terbayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes: { style:"text-align:right"} 
                },
                { field: "total_item_per_project", title: "Item Per Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes: { style:"text-align:right"} 
                },
                { field: "total_quantity_per_project", title: "Quantity Per Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes: { style:"text-align:right"} 
                },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    }
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: async function() {
    },
    methods: {
        detailInit: function (e) {
            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "BorrowingToolsReportDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.Details);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if (response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "item_type", title: "Tipe Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "uom", title: "UOM", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "quantity", title: "Quantity", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                ],
            });
        },
        async exportExcel(DateFrom, DateTo) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await borrowingToolsReportServices.borrowingToolsReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_PeminjamanAlat_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = borrowingToolsReportServices.borrowingToolsReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    },
}
</script>